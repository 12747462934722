import { useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Main from "./Main";
import usePageTracking from "./lib/usePackageTracking";
import ConsentBanner from "./lib/ConsentBanner";
import { updateConsent } from "./lib/gtag";

function App() {
  const [consentGiven, setConsentGiven] = useState(() => {
    return localStorage.getItem("consentGiven") === "true";
  });

  usePageTracking(consentGiven);

  const handleConsent = () => {
    setConsentGiven(true);
    localStorage.setItem("consentGiven", "true");
    updateConsent();
  };

  return (
    <>
      {!consentGiven && <ConsentBanner onAgree={handleConsent} />}
      <Routes>
        <Route path='/' element={<Main />} />
      </Routes>
    </>
  )
}
export default App;
