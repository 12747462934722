import React, { useRef, useState } from 'react';
import './App.css';
import axios from 'axios';

import topImage from './images/top.jpg'
import petImage from './images/concept.svg'
import priceImage from './images/price.jpg'
import orderButtonSvg from './images/order_button.svg';
import colorSectionImage from './images/color_selection.jpg';
import customMessageImage from './images/custom_message.svg';
import sizeSelectionImage from './images/size_selection.svg'
import productVideo from './videos/product.mp4'
import manufactureVideo from './videos/manufacturing.mov'
import safetySvg from './images/safety.svg'

import orderFlowText from './images/order_flow_text.svg'
import orderFlowImage1 from './images/orderFlowImage1.jpg'
import orderFlowImage2 from './images/orderFlowImage2.jpg'
import orderFlowImage3 from './images/orderFlowImage3.jpg'
import orderFlowImage4 from './images/orderFlowImage4.jpg'

import bestCanvasImage from './images/best_canvas.jpg'
import highQualityImage from './images/high_quality.jpg'
import lowFormaldehydeImage from './images/low_formaldehyde.jpg'
import specialAdhesiveImage from './images/special_adhesive.jpg'

import priceBelowImage from './images/price_below.jpg'
import emailjs from 'emailjs-com';

const Main = () => {
  const form = useRef();
  const [imageUrl, setImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleImageChange = async (e) => {
    const image = e.target.files[0];
    const formData = new FormData();
    formData.append("file", image);

    try {
      const response = await axios.post("https://file.io", formData, {
        params: { expires: "7d" },
      });

      setImageUrl(response.data.link);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData(form.current);
    const imageInfo = imageUrl ? `<a href="${imageUrl}" target="_blank">画像を見る</a>` : '画像添付無し';

    const templateParams = {
      from_name: formData.get('from_name'),
      reply_to: formData.get('reply_to'),
      message: formData.get('message'),
      image_info: imageInfo,
    };

    emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      templateParams,
      process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
    ).then((response) => {
      setLoading(false);
      alert('Email successfully sent!');
    }, (error) => {
      setLoading(false);
      alert('Failed sending email. Please try it again later.');
    });
  };

  return (
    <div className="App">
      <div className="header">
        <h1>Turn your pet's photo into a stunning custom art panel that you'll love!</h1>
      </div>
      <div className="art-panel">
        <img src={topImage} className="top-img" alt="Top"></img>
      </div>
      <div className="image-text-section">
        <div className="image-container">
          <img src={petImage} alt="Pet Art" />
        </div>
        <div className="text-container">
          <h2>Discover a vibrant and stylish "My Pet Interior"</h2>
          <p>
            How about transforming a cherished photo of your beloved pet into a unique interior piece? The iconic pop art style adds a vibrant accent to your room, creating a stylish and modern atmosphere.
          </p>
        </div>
      </div>
      <div className="circular-text-section">
        <div className="circle">
          <p>Refund and Cancellation Guarantee</p>
        </div>
        <div className="circle">
          <p>Gallery-Quality From Experts</p>
        </div>
        <div className="circle">
          <p>Created by Professional Designer</p>
        </div>
      </div>
      <div className="price-panel">
        <img src={priceImage} className="top-img" alt="Top"></img>
      </div>
      <div className="order-button-container">
        <img
          src={orderButtonSvg} alt="Order Button"
          onClick={() => window.location.href = "https://artdeli.co.jp/en/products/my-pet-popart-en"}
          style={{ cursor: "pointer" }} />
      </div>
      <div className="color-selection-panel">
        <img src={colorSectionImage} className="color-selection-img" alt="ColorSelection"></img>
      </div>
      <div className="custom-message-section">
        <h2>Custom Message</h2>
        <p>Add Your Favorite Text.</p>
        <img src={customMessageImage} alt="Custom Message" className="custom-message-img" />
        <div className="custom-message-container">
          <p>Kotaro / Saturday, January 1, 2022</p>
          <div className="custom-message-info">
            <span className="name">Name</span>
            <span className="birthdays">Birthdays</span>
          </div>
        </div>
        <div className="custom-message-detail">
          <p>Name, Anniversary, Message, etc.</p>
          <p>Make It Special With Your Favorite Text.</p>
        </div>
      </div>
      <div className="size-selection-section">
        <h2>SIZE SELECTION</h2>
        <p>YOU CAN CHOOSE FROM THREE DIFFERENT SIZES.</p>
        <img src={sizeSelectionImage} alt="Size Selection" className="size-selection-img" />
      </div>
      <div className="order-button-container">
        <img
          src={orderButtonSvg}
          alt="Order Button" onClick={() => window.location.href = "https://artdeli.co.jp/en/products/my-pet-popart-en"}
          style={{ cursor: "pointer" }} />
      </div>
      <div className="video-section">
        <video controls>
          <source src={productVideo} type="video/mp4" />
        </video>
        <div className="text-container">
          <h2>Send Us Your Photo and Get Custom Pop Art!</h2>
        </div>
      </div>
      <div className="safety-section">
        <div className="safety-container">
          <img src={safetySvg} alt="safety" />
        </div>
        <div className="text-container">
          <h2>Safety and Trust</h2>
          <p>We offer a Cancellation and Refund/Return Guarantee, and we're here to assist you during the creation process. Feel free to apply!</p>
        </div>
      </div>
      <div className="order-flow-section">
        <img src={orderFlowText} className="flow-text" alt="orderflow" />
        <h3>How to order</h3>
        <hr />
        <div className="flow-step">
          <div className="flow-step-image">
            <img src={orderFlowImage1} alt="Step 1" />
          </div>
          <div className="flow-step-content">
            <h4>01</h4>
            <p>
              • Choose your size<br />
              • Choose background color<br />
              • Send your message<br />
              • Upload photo<br />
              <small>Please set it up from the product page.</small>
            </p>
          </div>
        </div>
        <div className="flow-step">
          <div className="flow-step-image">
            <img src={orderFlowImage2} alt="Step 2" />
          </div>
          <div className="flow-step-content">
            <h4>02</h4>
            <p>
              Created by professional designers. We'll provide a preview image upon completion.<br />
              <span className="time-frame">2-3 days</span>
            </p>
          </div>
        </div>
        <div className="flow-step">
          <div className="flow-step-image">
            <img src={orderFlowImage3} alt="Step 3" />
          </div>
          <div className="flow-step-content">
            <h4>03</h4>
            <p>
              Once we receive your design approval, we will proceed.<br />
              <span className="time-frame">6-7 days</span>
            </p>
          </div>
        </div>
        <div className="flow-step">
          <div className="flow-step-image">
            <img src={orderFlowImage4} alt="Step 4" />
          </div>
          <div className="flow-step-content">
            <h4>04</h4>
            <p>Enjoy your unique 'My Pet Art'</p>
          </div>
        </div>
      </div>
      <div className="video-section">
        <video controls>
          <source src={manufactureVideo} type="video/mp4" />
        </video>
        <div className="text-container">
          <h2>High Quality One by one check carefully</h2>
          <p>ArtDeli products are carefully crafted one by one in Japan. Enjoy the luxurious quality made with skilled craftsmanship, chosen by famous theme parks and art exhibitions.</p>
        </div>
      </div>
      <div className="material-quality-section">
        <h2>High quality and commitment to materials</h2>
        <div className="quality-items">
          <div className="quality-item">
            <img src={highQualityImage} alt="High Quality" />
            <h3>High Quality</h3>
            <p>This material has a high density and excellent strength. We use eco-friendly materials that are stable and free from defects such as cracks or knots found in wood.</p>
          </div>
          <div className="quality-item">
            <img src={bestCanvasImage} alt="Best Canvas" />
            <h3>Best Canvas</h3>
            <p>This is the finest canvas crafted using traditional methods, favored by European masters and experts around the world.</p>
          </div>
          <div className="quality-item">
            <img src={specialAdhesiveImage} alt="Special Adhesive" />
            <h3>Special Adhesive</h3>
            <p>We use a special adhesive developed with the cooperation of Nitto Denko Corporation. It has also passed strength tests conducted during the testing period.</p>
          </div>
          <div className="quality-item">
            <img src={lowFormaldehydeImage} alt="Low Formaldehyde" />
            <h3>Low Formaldehyde</h3>
            <p>All materials used, including the canvas, wooden frames, and ink, have been tested and found free from harmful substances such as formaldehyde.</p>
          </div>
        </div>
      </div>
      <div className="price-panel">
        <img src={priceBelowImage}
          className="top-img"
          alt="Top"
          onClick={() => window.location.href = "https://artdeli.co.jp/en/products/my-pet-popart-en"}
          style={{ cursor: "pointer" }}
        ></img>
      </div>
      <div className="contact-form">
        {loading && (
          <div className="loading-screen">
            <p>Sending email...</p>
          </div>
        )}
        <h2>CONTACT</h2>
        <form ref={form} onSubmit={sendEmail}>
          <div className="form-group">
            <label htmlFor="name">Your name *</label>
            <input type="text" id="name" name="from_name" required />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email address *</label>
            <input type="email" id="email" name="reply_to" required />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message *</label>
            <textarea id="message" name="message" rows="4" required></textarea>
          </div>
          <div className="form-group">
            <label htmlFor="upload">Upload your image</label>
            <div className="upload-area">
              <input type="file" name="image" accept="image/*" onChange={handleImageChange} />
            </div>
          </div>
          <button type="submit" className="submit-button" disabled={loading}>Send</button>
        </form>
      </div>
      <div id="footer">
        <p>
          ＜Important Notes for Purchase＞
        </p>
        <p>
          The images shown are for illustrative purposes only. Colors displayed on screens, such as smartphones, may differ slightly from the actual product. We appreciate your understanding.
          During busy periods, delivery may be delayed. If you need your order urgently, please inquire about the estimated delivery time in advance.
          Our office is closed on weekends and public holidays.
          If the image you provide is deemed to violate public order, morals, or laws, we may refuse to process it. Thank you for your understanding.
          Any personal information, including images, collected will not be used for purposes other than those specified.
        </p>
      </div>
    </div>
  );
}

export default Main;