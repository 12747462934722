import React from 'react';
import styles from '../App.css';

function ConsentBanner({ onAgree }) {
  return (
    <div className="banner">
      <p>We use cookies to collect data to improve your experience. By clicking "Agree", you consent to data collection.</p>
      <button onClick={onAgree}>Agree</button>
    </div>
  );
}

export default ConsentBanner;
