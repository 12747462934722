export const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;

export function gtag() {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(arguments);
}

export function loadGtagScript() {
  if (!document.getElementById('gtag-script')) {
    const script = document.createElement("script");
    script.id = 'gtag-script';
    script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`;
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      gtag('js', new Date());
      gtag('config', GA_MEASUREMENT_ID);
    };
  }
}

export function setDefaultConsent() {
  gtag('consent', 'default', {
    'ad_storage': 'denied',
    'analytics_storage': 'denied',
    'ad_user_data': 'denied',
    'ad_personalization': 'denied',
  });
}

export function updateConsent() {
  gtag('consent', 'update', {
    'ad_storage': 'granted',
    'analytics_storage': 'granted',
    'ad_user_data': 'granted',
    'ad_personalization': 'granted',
  });
}
